import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
          
        </div>
        <div id="page" className="pageFrontpage">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/">PT</a>
                      </li>
                      <li>
                        <a href="/it/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper">
              <div className="home-slider">
                <div className="areaInner">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider_edit_disable{width:100%;min-height:20px;background:#999999;padding:10px;text-align:center;color:#fff}\n                        .flexslider_edit_disable.error{color:#cf0000}\n                        a:focus{outline:none!important;}\n                     "
                    }}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider-container3497 {margin:0 auto;position:relative;\t}.flexslider-container3497 img {max-height:450px;}.flexslider-container3497 li {margin-right:0px} .flexslider-container3497 {padding:0px;padding-right:0px;} .flexslider-container3497 img {max-width:100%;}\n                        .flexslider-container3497 {margin:0 auto 0px auto}\n                        .flexslider-container3497 h1 {margin:0;font-size:35px;font-weight:normal;text-align:left;}\n                     "
                    }}
                  />
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n$(window).on(\'load\', function(){\n$(".flexslider-container3497").flexslider({ animation:"slide",direction:"horizontal",reverse:false,animationLoop:true,startAt:0,slideshow :true,slideshowSpeed :7000,animationSpeed :600,initDelay :0,randomize :false,pauseOnAction :false,pauseOnHover :true,smoothHeight :false,useCSS :true,touch :true,video :true,controlNav :true,directionNav :false,keyboard :true,multipleKeyboard :true,mousewheel :false,pausePlay :false,itemMargin:0,minItems:1,maxItems :1,itemWidth:1680,namespace:"flex-",\nstart: function(){\n$(".flexslider-img-preloader").css("background-image","none");\n$(".flexslider-img-content").css("opacity","1");\n$(".flexslider-img-content").css("visibility","visible");\n}\n});\n});\n'
                    }}
                  />
                  <div className="external-flexslider-container3497 flexslider-img-preloader">
                    <div className="default-flexslider flexslider-container3497 flexslider-img-content">
                      <ul className="slides3497 default-flex-slides slides">
                        <li>
                          <a href="/en/our-products/instantglow/">
                            {" "}
                            <img src="/assets/images/2017-pizbuin_1860x502px_Instant-Glow-Buoy.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                <br />
                                INSTANTLY
                                <br />
                                PROTECTED &amp; BEAUTIFUL
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN INSTANT GLOW<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/en/our-products/instantglow/"
                              >
                                Read more
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/en/our-products/protect_cool/">
                            {" "}
                            <img src="/assets/images/2017-pizbuin_1680x502px_ProtectCool.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                Unbelievably Fresh -<br />
                                Instantly protected!
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN PROTECT &amp; COOL<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/en/our-products/protect_cool/"
                              >
                                Read more
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/en/our-products/allergy/">
                            {" "}
                            <img src="/assets/images/2017-pizbuin_1680x502px_Allergy.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                <br />
                                Sun sensitive
                                <br /> skin protection
                                <br />
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN<sup>®</sup> ALLERGY
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/en/our-products/allergy/"
                              >
                                Read more
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    style={{
                      clear: "both"
                    }}
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent clearfix">
                <div className="section">
                  <figure>
                    <a href="/en/our-products/all_products/">
                      <img src="/assets/images/CallToAction_640x370px_ProductRange.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/en/our-products/all_products/">
                        SUNCARE FOR EVERY NEED
                      </a>
                    </h3>
                    <h4>
                      <a href="/en/our-products/all_products/">
                        Browse the complete PIZ BUIN<sup>®</sup> product range
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/en/know_the_sun/">
                      <img src="/assets/images/CallToAction_640x370px_KnowTheSun.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/en/know_the_sun/">KNOW THE SUN</a>
                    </h3>
                    <h4>
                      <a href="/en/know_the_sun/">
                        Our guide to enjoying the sun safely
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/en/our-heritage/">
                      <img src="/assets/images/mobile_landing_OurHeritage_x2.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/en/our-heritage/">OUR HERITAGE</a>
                    </h3>
                    <h4>
                      <a href="/en/our-heritage/">
                        Suncare specialist for over 70 years
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        
        <script src="/assets/js/vendor/jquery.fancybox.min.js" />
        <script src="/assets/js/vendor/iealert.min.js" />

      </div>
    );
  }
}

export default Page;
